<template>
  <div>
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-alert :value="true" type="info">
            <label
              class="text-uppercase"
              @click="voltar()"
              style="text-decoration: underline;cursor:pointer;"
            >{{canal.razao_social}}</label>
          </v-alert>
          <v-tabs centered slider-color="primary" v-model="tabs">
            <v-tab>Usuários</v-tab>
            <v-tab-item>
              <UsuarioLista />
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
const UsuarioLista = () => import('./Usuario-Lista')
const canalModule = () => import('./../../../services/unidade')
import { mapGetters } from 'vuex'

export default {
  name: 'LojaTabs',
  components: {
    UsuarioLista
  },
  mounted () {
    this.getUnidade()
  },
  data () {
    return {
      tabs: 0,
      canal: {}
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    async getUnidade () {
      let canalDecode = atob(this.$route.params.unidade)
      let canalService = await canalModule()
      let resp = await canalService.getByCodigo(this.$axios, canalDecode)
      if (resp.status === 200 && resp.data) {
        this.canal = resp.data
      }
    },
    async voltar () {
      if (this.currentUser.tipo === 'L') {
        return
      }
      if (this.canal.tipo === 'C') {
        this.$router.push('/canal')
      } else {
        this.$router.push(`/loja/${this.$route.params.unidade}`)
      }
    },
  }
}
</script>